#projectwriteup {
    background-color: $primary-color;
    background-image: linear-gradient(
      135deg,
      $primary-color 0%,
      $secondary-color 100%
    );
    color: $white-color;
    height: 100%;
    border-top: 0px;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    padding-bottom: 10%;
  
    @include respond(tab-land) {
      height: 100%;
      -webkit-clip-path: none;
      clip-path: none;
    }
  
    .projectwriteup-wrapper {
      @include respond(phone) {
        padding-bottom: 5rem;
      }

        &__image {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
  
        @include respond(tab-land) {
          height: 100%;
        }
        @include respond(tab-port-sm) {
          padding-bottom: 6.4rem;
        }
        }
  
        &__info {
            display: flex;
            height: 100%;
            justify-content: center;
            flex-direction: column;
    
            @include respond(tab-port-sm) {
            align-items: center;
            }
    
            &-text {
            text-align: center;
    
            @include respond(tab-port) {
                text-align: center;
            }
            @include respond(tab-port-sm) {
                text-align: center;
            }
    
                &--important {
                    background: darken($secondary-color, 1%);
                    display: inline-block;
                    font-style: italic;
                    padding: 0 0.3rem;
                    margin: 0.3rem 0;
                    line-height: 1.6;
        
                    @include respond(phone) {
                    display: inline;
                    margin: 0;
                    padding: 0;
                    background: transparent;
                    font-style: normal;
                    }
                }
            }
        }
    }
}