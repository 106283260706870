/* Call to Action Button */
.cta-btn {
  display: inline-block;
  position: relative;
  padding: 0.8rem $default-font-size;
  font-weight: bold;
  line-height: 1;
  z-index: 1;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 0px;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  }
}

/* Hero Style */
.cta-btn--hero {
  @include supportColorForIE11();
  border: 2px solid transparent;
  border-image: linear-gradient(
    135deg,
    $primary-color 0%,
    $secondary-color 100%
  );
  border-image-slice: 1;
  @include supportIE11() {
    color: $secondary-color !important;
    &:hover {
      color: $white-color !important;
    }
  }

  @include respond(phone) {
    background-image: none;
    border: 2px solid $secondary-color;
    -webkit-text-fill-color: $secondary-color;
  }

  &::after {
    background-image: linear-gradient(
      135deg,
      $primary-color 0%,
      $secondary-color 100%
    );

    @include respond(phone) {
      background-image: none;
    }
  }

  &:hover {
    -webkit-text-fill-color: $white-color;
    text-decoration: none;

    @include respond(phone) {
      -webkit-text-fill-color: $secondary-color;
    }

    &::after {
      width: 100%;
    }
  }
}

/* Back button Style */
.cta-btn--back {
  @include supportColorForIE11();
  border: 2px solid transparent;
  border-image: linear-gradient(
    135deg,
    $primary-color 0%,
    $secondary-color 100%
  );
  border-image-slice: 1;
  @include supportIE11() {
    color: $secondary-color !important;
    &:hover {
      color: $white-color !important;
    }
  }

  @include respond(phone) {
    background-image: none;
    border: 2px solid $secondary-color;
    -webkit-text-fill-color: $secondary-color;
  }

  &::after {
    background-image: linear-gradient(
      135deg,
      $primary-color 0%,
      $secondary-color 100%
    );

    @include respond(phone) {
      background-image: none;
    }
  }

  &:hover {
    -webkit-text-fill-color: $white-color;
    text-decoration: none;

    @include respond(phone) {
      -webkit-text-fill-color: $secondary-color;
    }

    &::after {
      width: 100%;
    }
  }
}

/* Resume Style */
.cta-btn--resume {
  color: $white-color;
  border: 2px solid $white-color;

  &::after {
    background: $white-color;
  }

  &:hover {
    color: $secondary-color;
    text-decoration: none;

    &::after {
      width: 100%;
    }
  }
}

/* Project Writeup Style */
.cta-btn--projectwriteup {
  color: $white-color;
  border: 2px solid $white-color;
  margin: auto;

  &::after {
    background: $white-color;
  }

  &:hover {
    color: $secondary-color;
    text-decoration: none;

    &::after {
      width: 100%;
    }
  }
}

/* Arrow Button */
.up i {
  color: #272727;
}


/* Slideshow */
/*.cta-btn--slideshow {*/
  * {box-sizing:border-box}

  /* Hide the images by default */
  .mySlides {display: none;}
  .img {vertical-align: middle;}

  /* Slideshow container */
  .slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
  }

  /* Next & previous buttons */
  .prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: black;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  }


  /* Position the "next button" to the right */
  .prev {
  left: 0;
  border-radius: 3px 0 0 3px;
  }

  /* Position the "next button" to the right */
  .next {
  right: 0;
  border-radius: 3px 0 0 3px;
  }

  /* On hover, add a black background color with a little bit see-through */
  .prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
  }

  /* Caption text */
  .text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
  }

  /* Number text (1/3 etc) */
  .numbertext {
  color: #000000;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
  }

  /* The dots/bullets/indicators */
  .dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  }

  .active, .dot:hover {
  background-color: #717171;
  }

  /* Fading animation */
  .fade {
  animation-name: fade;
  animation-duration: 1.5s;
  }

  @keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
  }

  /* On smaller screens, decrease text size */
  @media only screen and (max-width: 300px) {
  .prev, .next,.text {font-size: 11px}
  }